import { useState } from "react";

export const useResellers = (props) => {

  const googleMapsSiteKey = String(process.env.REACT_APP_GOOGLE_MAPS_SITE_KEY);
  const branchData = props.data ? props.data.branches : [];

  const [showModal, changeShowModal] = useState(false);
  const [activeBranchName, changeActiveBranchName] = useState("No name");
  const [activeBranchData, changeActiveBranchData] = useState(<></>);

  const handleClick = (branch) => {

    const phone = (branch.phone ? branch.phone : props.data.branch_default_phone);
    const email = (branch.email ? branch.email : props.data.branch_default_email);

    changeActiveBranchName(branch.name);
    changeActiveBranchData(
      <table id="modal-branch-data">
        <tbody>
          <tr>
            <td><i className={"fa fa-phone icon-font-size"} aria-hidden="true" /></td><td>Teléfono:</td><td>{phone}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-envelope icon-font-size"} aria-hidden="true" /></td><td>Email:</td><td>{email}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-building icon-font-size"} aria-hidden="true" /></td><td>Dirección:</td><td>{branch.address}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-map-marker icon-font-size"} aria-hidden="true" /></td><td>Localidad:</td><td>{branch.city_name + ",\n" + branch.province_name}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-globe icon-font-size"} aria-hidden="true" /></td><td>País:</td><td>{branch.country_name}</td>
          </tr>
        </tbody>
      </table>
    )

    changeShowModal(true);
  }

  return {
    googleMapsSiteKey,
    showModal,
    changeShowModal,
    handleClick,
    branchData,
    activeBranchName, 
    activeBranchData
  };
};

export default useResellers;
