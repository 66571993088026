import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Home } from "./pages/Home/Home"; 
import { NotFound } from "./pages/NotFound/NotFound"; 
import JsonData from "./data/data.json";
import "./App.css";

const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
          <Route
            exact path="/" 
            element={<Home data={landingPageData} />}
          />
          <Route
            path="*"
            element={<NotFound data={landingPageData} />}
          />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
