import React from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import { useResellers } from "./useResellers.js";
import markerIcon from "./assets/marker_icon.webp";
import "./Resellers.css";

export const Resellers = (props) => {

  let {
    googleMapsSiteKey,
    showModal,
    changeShowModal,
    handleClick,
    branchData,
    activeBranchName, 
    activeBranchData
  } = useResellers(props);

  return (
    <div id="resellers" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            {props.data ? props.data.title : "Loading"}
          </h2>
          <p>
            {props.data ? props.data.description : "Loading"}
          </p>
        </div>
      </div>
      { /* Important! Always set the container height explicitly */ }
      <div id="resellers-map-container" className="map-container" style={{ height: "60vh" }}>
        <APIProvider apiKey={googleMapsSiteKey}>
          <Map
            mapId='ALIENTECH_AR_RESELLERS'
            defaultZoom={props.data ? props.data.map_default_zoom : 1}
            defaultCenter={props.data ? props.data.map_default_center : {lat: -33.8567844, lng: 151.213108}}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
          >
          {
            branchData.map((branch) => (

              (branch.show_in_map ? (
                <AdvancedMarker
                  key={branch.id}
                  position={branch.position}
                  clickable={true}
                  onClick={() => handleClick(branch)}
                >
                  <img
                    src={markerIcon}
                    className="map-custom-marker"
                    alt={branch.name}
                  />
                </AdvancedMarker>
              ) : <></>)
            ))              
          }
          </Map>
        </APIProvider>
      </div>
      <CustomModal
        show={showModal}
        onHide={() => changeShowModal(false)}
        title={activeBranchName}
        body={activeBranchData}
      />
    </div>
  );
};
